import React from 'react'
import { useTranslation } from 'contexts/Localization'
import DialogModal from 'components/DialogModal'
import { useDialogConnectWallet } from 'contexts/DialogConnectWallet'
import { ButtonsConnectWallet } from '@ftmbet/uikit'
import useAuth from 'hooks/useAuth'

const DialogConnectWallet = () => {
  const { isVisible, setIsVisible } = useDialogConnectWallet()
  const { t } = useTranslation()
  const { login } = useAuth()

  return (
    <DialogModal isOpen={isVisible} setIsOpen={setIsVisible} title={t('Connect wallet')}>
      <div className="grid gap-4 grid-cols-2 pt-2">
        <ButtonsConnectWallet login={login} onDismiss={() => setIsVisible(false)} />
      </div>
    </DialogModal>
  )
}

export default DialogConnectWallet
