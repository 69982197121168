import React from 'react'
import { IIconProps } from './types'
import styles from './index.module.css'

export const IconSpinner = (props: IIconProps) => {
  const { className } = props
  return (
    <svg aria-hidden="true" className={`${className || ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path opacity=".1" d="M14 0 H18 V8 H14 z" transform="rotate(0 16 16)">
        <animate attributeName="opacity" from="1" to=".1" dur="1s" repeatCount="indefinite" begin="0" />
      </path>
      <path opacity=".1" d="M14 0 H18 V8 H14 z" transform="rotate(45 16 16)">
        <animate attributeName="opacity" from="1" to=".1" dur="1s" repeatCount="indefinite" begin="0.125s" />
      </path>
      <path opacity=".1" d="M14 0 H18 V8 H14 z" transform="rotate(90 16 16)">
        <animate attributeName="opacity" from="1" to=".1" dur="1s" repeatCount="indefinite" begin="0.25s" />
      </path>
      <path opacity=".1" d="M14 0 H18 V8 H14 z" transform="rotate(135 16 16)">
        <animate attributeName="opacity" from="1" to=".1" dur="1s" repeatCount="indefinite" begin="0.375s" />
      </path>
      <path opacity=".1" d="M14 0 H18 V8 H14 z" transform="rotate(180 16 16)">
        <animate attributeName="opacity" from="1" to=".1" dur="1s" repeatCount="indefinite" begin="0.5s" />
      </path>
      <path opacity=".1" d="M14 0 H18 V8 H14 z" transform="rotate(225 16 16)">
        <animate attributeName="opacity" from="1" to=".1" dur="1s" repeatCount="indefinite" begin="0.675s" />
      </path>
      <path opacity=".1" d="M14 0 H18 V8 H14 z" transform="rotate(270 16 16)">
        <animate attributeName="opacity" from="1" to=".1" dur="1s" repeatCount="indefinite" begin="0.75s" />
      </path>
      <path opacity=".1" d="M14 0 H18 V8 H14 z" transform="rotate(315 16 16)">
        <animate attributeName="opacity" from="1" to=".1" dur="1s" repeatCount="indefinite" begin="0.875s" />
      </path>
    </svg>
  )
}

export const IconClock = (props: IIconProps) => {
  const { className } = props
  return (
    <svg
      aria-hidden="true"
      className={`${className || ''}`}
      width="20"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10Zm0-2a8 8 0 1 0 0-16.001A8 8 0 0 0 10 18Zm1-8h4v2H9V5h2v5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconPredictionExpired = (props: IIconProps) => {
  const { className } = props
  return (
    <svg
      aria-hidden="true"
      className={`${className || ''}`}
      width="20"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10Zm0-2a8 8 0 1 0 0-16.001A8 8 0 0 0 10 18ZM6.523 5.109l8.368 8.368a6.041 6.041 0 0 1-1.414 1.414L5.109 6.523A6.04 6.04 0 0 1 6.523 5.11v-.001Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconPredictionLive = (props: IIconProps) => {
  const { className } = props
  return (
    <svg
      aria-hidden="true"
      className={`${className || ''}`}
      width="20"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10Zm0-2a8 8 0 1 0 0-16.001A8 8 0 0 0 10 18ZM8.622 6.415l4.879 3.252a.4.4 0 0 1 0 .666l-4.88 3.252A.4.4 0 0 1 8 13.253V6.747a.4.4 0 0 1 .622-.332Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconPredictionNext = (props: IIconProps) => {
  const { className } = props
  return (
    <svg
      aria-hidden="true"
      className={`${className || ''}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.375 15.103C20.0239 13.5613 20.1733 11.855 19.8021 10.224C19.431 8.59303 18.558 7.11938 17.306 6.01024C16.0539 4.9011 14.4857 4.21225 12.8219 4.04055C11.158 3.86884 9.48222 4.22292 8.03004 5.05299L7.03804 3.31599C8.55567 2.4486 10.2742 1.99435 12.0222 1.99856C13.7703 2.00278 15.4866 2.4653 17 3.33999C21.49 5.93199 23.21 11.482 21.117 16.11L22.459 16.884L18.294 19.098L18.129 14.384L19.375 15.103ZM4.62504 8.89699C3.97618 10.4387 3.82682 12.145 4.19796 13.776C4.56909 15.4069 5.44205 16.8806 6.69411 17.9897C7.94617 19.0989 9.51436 19.7877 11.1782 19.9594C12.842 20.1311 14.5179 19.7771 15.97 18.947L16.962 20.684C15.4444 21.5514 13.7258 22.0056 11.9778 22.0014C10.2298 21.9972 8.51347 21.5347 7.00004 20.66C2.51004 18.068 0.790039 12.518 2.88304 7.88999L1.54004 7.11699L5.70504 4.90299L5.87004 9.61699L4.62404 8.89799L4.62504 8.89699ZM13.415 14.828L10.584 12L7.75604 14.828L6.34204 13.414L10.585 9.17199L13.414 12L16.243 9.17199L17.657 10.586L13.414 14.828H13.415Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconInfo = (props: IIconProps) => {
  const { className } = props
  return (
    <svg
      aria-hidden="true"
      className={`${className || ''}`}
      width="14"
      height="14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 13.667A6.667 6.667 0 1 1 7 .333a6.667 6.667 0 0 1 0 13.334Zm0-1.334A5.334 5.334 0 1 0 7 1.665a5.334 5.334 0 0 0 0 10.668Zm-.667-8.666h1.334V5H6.333V3.667Zm0 2.666h1.334v4H6.333v-4Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconArrowUp = (props: IIconProps) => {
  const { className } = props
  return (
    <svg
      aria-hidden="true"
      className={`${className || ''}`}
      width="12"
      height="12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.667 3.219v8.114H5.333V3.22L1.757 6.795l-.942-.943L6 .667l5.185 5.185-.942.943-3.576-3.576Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconArrowDown = (props: IIconProps) => {
  const { className } = props
  return (
    <svg
      aria-hidden="true"
      className={`${className || ''}`}
      width="12"
      height="12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.333 8.781V.667h1.334V8.78l3.576-3.576.942.943L6 11.333.815 6.148l.942-.943 3.576 3.576Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconArrowStart = (props: IIconProps) => {
  const { className } = props
  return (
    <svg
      aria-hidden="true"
      className={`${styles.rtlFriendly} ${className || ''}`}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m3.828 7 5.364-5.364L7.778.222 0 8l7.778 7.778 1.414-1.414L3.828 9H16V7H3.828Z" fill="currentColor" />
    </svg>
  )
}

export const IconArrowEnd = (props: IIconProps) => {
  const { className } = props
  return (
    <svg
      className={`${styles.rtlFriendly} ${className || ''}`}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {' '}
      <path
        d="M12.172 7L6.808 1.636L8.222 0.222L16 8L8.222 15.778L6.808 14.364L12.172 9H0V7H12.172Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconTelegram = (props: IIconProps) => {
  const { className } = props

  return (
    <svg
      aria-hidden="true"
      className={`${className || ''}`}
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none">
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-3.11-8.83-2.498-.779c-.54-.165-.543-.537.121-.804l9.733-3.76c.565-.23.885.061.702.79l-1.657 7.82c-.116.557-.451.69-.916.433l-2.551-1.888-1.189 1.148c-.122.118-.221.219-.409.244-.187.026-.341-.03-.454-.34l-.87-2.871-.012.008z" />
      </g>
    </svg>
  )
}

export const IconHome = (props: IIconProps) => {
  const { className } = props
  return (
    <svg
      aria-hidden="true"
      className={`${className || ''}`}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 18C18 18.2652 17.8946 18.5195 17.7071 18.7071C17.5196 18.8946 17.2652 19 17 19H1C0.734784 19 0.48043 18.8946 0.292893 18.7071C0.105357 18.5195 7.92656e-08 18.2652 7.92656e-08 18V7.31396C-6.00579e-05 7.16291 0.0340991 7.01381 0.0999132 6.87785C0.165727 6.7419 0.261486 6.62261 0.38 6.52896L8.38 0.217962C8.55655 0.0784525 8.77499 0.00256348 9 0.00256348C9.22501 0.00256348 9.44345 0.0784525 9.62 0.217962L17.62 6.52796C17.7386 6.62172 17.8345 6.74116 17.9003 6.8773C17.9661 7.01345 18.0002 7.16274 18 7.31396V18ZM4 9.99996C4 11.326 4.52678 12.5978 5.46447 13.5355C6.40215 14.4732 7.67392 15 9 15C10.3261 15 11.5979 14.4732 12.5355 13.5355C13.4732 12.5978 14 11.326 14 9.99996H12C12 10.7956 11.6839 11.5587 11.1213 12.1213C10.5587 12.6839 9.79565 13 9 13C8.20435 13 7.44129 12.6839 6.87868 12.1213C6.31607 11.5587 6 10.7956 6 9.99996H4Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconCrown = (props: IIconProps) => {
  const { className } = props
  return (
    <svg
      aria-hidden="true"
      className={`${className || ''}`}
      width="20"
      height="19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 17h20v2H0v-2ZM0 3l5 3 5-6 5 6 5-3v12H0V3Z" fill="currentColor" />
    </svg>
  )
}

export const IconFTM = (props: IIconProps) => {
  const { className } = props
  return (
    <svg aria-hidden="true" className={`${className || ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <defs>
        <mask id="mask" x="10" y="6" width="93.1" height="20" maskUnits="userSpaceOnUse">
          <path className="fill-neutral-fg-100" d="M10 6h93.1v20H10Z" />
        </mask>
      </defs>
      <g>
        <g>
          <circle cx="16" cy="16" r="16" style={{ fill: '#13b5ec' }} />
          <path
            className="fill-neutral-fg-100"
            d="m17.2 12.9 3.6-2.1V15Zm3.6 9L16 24.7l-4.8-2.8V17l4.8 2.8 4.8-2.8Zm-9.6-11.1 3.6 2.1-3.6 2.1Zm5.4 3.1 3.6 2.1-3.6 2.1Zm-1.2 4.2L11.8 16l3.6-2.1Zm4.8-8.3L16 12.2l-4.2-2.4L16 7.3ZM10 9.4v13.1l6 3.4 6-3.4V9.4L16 6Z"
            style={{ mask: 'url(#mask)' }}
          />
        </g>
      </g>
    </svg>
  )
}

export const IconGitbook = (props) => {
  const { className } = props
  return (
    <svg
      aria-hidden="true"
      className={`${className || ''}`}
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.802 17.77a.703.703 0 1 1-.002 1.406.703.703 0 0 1 .002-1.406m11.024-4.347a.703.703 0 1 1 .001-1.406.703.703 0 0 1-.001 1.406m0-2.876a2.176 2.176 0 0 0-2.174 2.174c0 .233.039.465.115.691l-7.181 3.823a2.165 2.165 0 0 0-1.784-.937c-.829 0-1.584.475-1.95 1.216l-6.451-3.402c-.682-.358-1.192-1.48-1.138-2.502.028-.533.212-.947.493-1.107.178-.1.392-.092.62.027l.042.023c1.71.9 7.304 3.847 7.54 3.956.363.169.565.237 1.185-.057l11.564-6.014c.17-.064.368-.227.368-.474 0-.342-.354-.477-.355-.477-.658-.315-1.669-.788-2.655-1.25-2.108-.987-4.497-2.105-5.546-2.655-.906-.474-1.635-.074-1.765.006l-.252.125C7.78 6.048 1.46 9.178 1.1 9.397.457 9.789.058 10.57.006 11.539c-.08 1.537.703 3.14 1.824 3.727l6.822 3.518a2.175 2.175 0 0 0 2.15 1.862 2.177 2.177 0 0 0 2.173-2.14l7.514-4.073c.38.298.853.461 1.337.461A2.176 2.176 0 0 0 24 12.72a2.176 2.176 0 0 0-2.174-2.174"
        stroke="none"
      />
    </svg>
  )
}

export const IconHistory = (props) => {
  const { className } = props
  return (
    <svg
      aria-hidden="true"
      className={`${className || ''}`}
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none">
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12h2a8 8 0 1 0 1.865-5.135L8 9H2V3l2.447 2.446A9.98 9.98 0 0 1 12 2zm1 5v4.585l3.243 3.243-1.415 1.415L11 12.413V7h2z" />
      </g>
    </svg>
  )
}

export const IconExternalLink = (props) => {
  const { className } = props
  return (
    <svg
      aria-hidden="true"
      className={`${className || ''}`}
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none">
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M10 6v2H5v11h11v-5h2v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h6zm11-3v8h-2V6.413l-7.793 7.794-1.414-1.414L17.585 5H13V3h8z" />
      </g>
    </svg>
  )
}

export const IconTokenWrap = (props) => {
  const { className } = props
  return (
    <svg
      aria-hidden="true"
      className={`${className || ''}`}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40 20.8749C40 31.8892 31.0457 39.9999 20 39.9999C8.95429 39.9999 0 31.8892 0 20.8749C0 9.8606 8.95429 1.74988 20 1.74988C31.0457 1.74988 40 9.8606 40 20.8749Z"
        fill="#6C7D2B"
      />
      <path
        d="M40 19.125C40 30.1393 31.0457 38.25 20 38.25C8.95429 38.25 0 30.1393 0 19.125C0 8.11065 8.95429 0 20 0C31.0457 0 40 8.11065 40 19.125Z"
        fill="#CFEE59"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.8235 5.06348C32.0693 3.51458 30.0154 2.27371 27.7514 1.40808L1.70288 27.1735C2.71519 29.2914 4.11859 31.1749 5.82491 32.7577L33.8235 5.06348ZM14.4605 37.4362C13.0565 37.0714 11.7153 36.5695 10.4551 35.9441L37.3907 9.30143C38.1025 10.4636 38.6886 11.712 39.1314 13.0336L14.4605 37.4362Z"
        fill="#A5C047"
      />
      <path
        d="M36.25 18.875C36.25 27.7022 29.0306 34 20.125 34C11.2194 34 4 27.7022 4 18.875C4 10.0478 11.2194 3.75 20.125 3.75C29.0306 3.75 36.25 10.0478 36.25 18.875Z"
        fill="#191326"
      />
      <path
        d="M34.75 12.246C29.8986 5.93843 20.9673 4.03635 13.8597 8.11005C6.877 12.1121 4.04567 20.5109 6.8656 27.75C5.0587 25.3175 4 22.3017 4 18.9144C4 10.0642 11.2272 3.75 20.1425 3.75C26.5973 3.74999 32.1673 7.05998 34.75 12.246Z"
        fill="#6C7D2B"
      />
    </svg>
  )
}

export const IconFTMWithoutBg = (props) => {
  const { className } = props
  return (
    <svg
      aria-hidden="true"
      fill="currentColor"
      className={`${className || ''}`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.296 4.087a.78.78 0 0 0-.71-.003L7.629 6.08a.78.78 0 0 0-.429.696v9.755c0 .288.16.553.414.689l3.963 2.107a.78.78 0 0 0 .725.004l4.077-2.11a.78.78 0 0 0 .421-.694V6.893a.78.78 0 0 0-.421-.692l-4.083-2.114zm-.44.532a.18.18 0 0 1 .164.001l4.083 2.114a.18.18 0 0 1 .064.055.18.18 0 0 1-.066.056l-3.957 1.997a.18.18 0 0 1-.164 0L7.897 6.727a.18.18 0 0 1-.063-.055.18.18 0 0 1 .065-.056l3.957-1.998zM7.8 7.353v4.087-.586l3.424-1.728L7.8 7.353zm0 4.86v4.319a.18.18 0 0 0 .096.159l3.963 2.107a.18.18 0 0 0 .167 0l4.077-2.11a.18.18 0 0 0 .097-.16V11.74v.587l-3.786 1.91a.78.78 0 0 1-.71-.003L7.8 12.213zm8.4-1.245V6.893v.574l-3.424 1.728 3.424 1.773zm-8.303.62a.18.18 0 0 1-.063-.056.181.181 0 0 1 .065-.056L11.7 9.558v3.998l-3.803-1.968zm4.403 2.035V9.624l3.803 1.969a.18.18 0 0 1 .064.055.18.18 0 0 1-.066.057l-3.8 1.918z"
      />
      <path d="m17.125 4.978-.367-.19-.276.532.367.19c.338.176.55.525.55.906v.495h.6v-.495a1.62 1.62 0 0 0-.874-1.438zM7.242 18.544l-.367-.19A1.62 1.62 0 0 1 6 16.915v-.494h.6v.494c0 .381.212.73.55.906l.368.19-.276.533z" />
    </svg>
  )
}

export const IconPlay = (props) => {
  const { className } = props
  return (
    <svg
      aria-hidden="true"
      className={`${className || ''}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10Zm0-2a8 8 0 1 0 0-16.001A8 8 0 0 0 10 18ZM8.622 6.415l4.879 3.252a.4.4 0 0 1 0 .666l-4.88 3.252A.4.4 0 0 1 8 13.253V6.747a.4.4 0 0 1 .622-.332Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconTwitter = (props) => {
  const { className } = props
  return (
    <svg
      aria-hidden="true"
      className={`${className || ''}`}
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none">
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M15.3 5.55a2.9 2.9 0 0 0-2.9 2.847l-.028 1.575a.6.6 0 0 1-.68.583l-1.561-.212c-2.054-.28-4.022-1.226-5.91-2.799-.598 3.31.57 5.603 3.383 7.372l1.747 1.098a.6.6 0 0 1 .034.993L7.793 18.17c.947.059 1.846.017 2.592-.131 4.718-.942 7.855-4.492 7.855-10.348 0-.478-1.012-2.141-2.94-2.141zm-4.9 2.81a4.9 4.9 0 0 1 8.385-3.355c.711-.005 1.316.175 2.669-.645-.335 1.64-.5 2.352-1.214 3.331 0 7.642-4.697 11.358-9.463 12.309-3.268.652-8.02-.419-9.382-1.841.694-.054 3.514-.357 5.144-1.55C5.16 15.7-.329 12.47 3.278 3.786c1.693 1.977 3.41 3.323 5.15 4.037 1.158.475 1.442.465 1.973.538z" />
      </g>
    </svg>
  )
}

export const IconWarning = (props) => {
  const { className } = props
  return (
    <svg
      aria-hidden="true"
      className={`${className || ''}`}
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m11.866 1 9.526 16.5a1 1 0 0 1-.866 1.5H1.474a1 1 0 0 1-.866-1.5L10.134 1a1 1 0 0 1 1.732 0Zm-8.66 16h15.588L11 3.5 3.206 17ZM10 14h2v2h-2v-2Zm0-7h2v5h-2V7Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconError = (props) => {
  const { className } = props
  return (
    <svg
      aria-hidden="true"
      className={`${className || ''}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10Zm0-2a8 8 0 1 0 0-16.001A8 8 0 0 0 10 18ZM9 5h2v2H9V5Zm0 4h2v6H9V9Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconCheckMark = (props) => {
  const { className } = props
  return (
    <svg
      aria-hidden="true"
      className={`${className || ''}`}
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 10.172 16.192.979l1.415 1.414L7 13 .636 6.636 2.05 5.222 7 10.172Z" fill="currentColor" />
    </svg>
  )
}

export const IconClose = (props: IIconProps) => {
  const { className } = props
  return (
    <svg
      aria-hidden="true"
      className={`${className || ''}`}
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 512 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
        d="M368 368L144 144m224 0L144 368"
      />
    </svg>
  )
}

export const IconChevronDown = (props) => {
  const { className } = props
  return (
    <svg
      aria-hidden="true"
      className={`${className || ''}`}
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline points="6 9 12 15 18 9" />
    </svg>
  )
}

export const IconLive = (props) => {
  const { className } = props
  return (
    <svg
      aria-hidden="true"
      className={`${className || ''}`}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0001 27.3333C6.63608 27.3333 0.666748 21.364 0.666748 14C0.666748 6.636 6.63608 0.666666 14.0001 0.666666C21.3641 0.666666 27.3334 6.636 27.3334 14C27.3334 21.364 21.3641 27.3333 14.0001 27.3333ZM14.0001 24.6667C16.8291 24.6667 19.5422 23.5429 21.5426 21.5425C23.5429 19.5421 24.6667 16.829 24.6667 14C24.6667 11.171 23.5429 8.45792 21.5426 6.45753C19.5422 4.45714 16.8291 3.33333 14.0001 3.33333C11.1711 3.33333 8.458 4.45714 6.45761 6.45753C4.45722 8.45792 3.33341 11.171 3.33341 14C3.33341 16.829 4.45722 19.5421 6.45761 21.5425C8.458 23.5429 11.1711 24.6667 14.0001 24.6667V24.6667ZM12.1627 9.22L18.6681 13.556C18.7412 13.6047 18.8012 13.6707 18.8427 13.7482C18.8842 13.8256 18.9059 13.9121 18.9059 14C18.9059 14.0879 18.8842 14.1744 18.8427 14.2518C18.8012 14.3293 18.7412 14.3953 18.6681 14.444L12.1614 18.78C12.0812 18.8332 11.9881 18.8637 11.8919 18.8682C11.7958 18.8727 11.7002 18.8512 11.6153 18.8058C11.5304 18.7604 11.4594 18.6929 11.4098 18.6104C11.3601 18.528 11.3338 18.4336 11.3334 18.3373V9.66267C11.3336 9.5662 11.3599 9.47159 11.4096 9.38891C11.4593 9.30623 11.5305 9.23857 11.6156 9.19315C11.7007 9.14772 11.7965 9.12623 11.8929 9.13096C11.9892 9.13569 12.0825 9.16646 12.1627 9.22V9.22Z"
        fill="currentColor"
      />
    </svg>
  )
}
