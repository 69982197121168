/* eslint-disable react/button-has-type */
import React from 'react'
import { Link } from 'react-router-dom'
import { IconSpinner } from 'components/Icon'
import { IButtonLinkProps, ICtaProps } from './types'
import ui from './ui'

export function ButtonCta(props: ICtaProps) {
  const { children, extraCss, variant, scale, isLoading, disabled, ...rest } = props
  return (
    <button
      disabled={disabled || isLoading}
      className={`
            focus:outline-none
            cursor-pointer
            disabled:cursor-not-allowed
            flex items-center
            ${ui.variant[variant]}
            ${ui.scale[scale]}
            ${disabled || isLoading ? ui.variant.disabled : ''}
            ${extraCss || ''}`}
      {...rest}
    >
      {isLoading && <IconSpinner className="mie-1 w-5 text-currentColor" />}
      {children}
    </button>
  )
}

export function ButtonLink(props: IButtonLinkProps) {
  const { children, extraCss, variant, scale, external, to, ...rest } = props
  if (!external)
    return (
      <Link
        className={`
  focus:outline-none
  cursor-pointer
  disabled:cursor-not-allowed
  ${ui.variant[variant]}
  ${ui.scale[scale]}
  ${extraCss || ''}`}
        to={to}
        {...rest}
      >
        {children}
      </Link>
    )
  return (
    <a
      className={`
        focus:outline-none
        cursor-pointer
        disabled:cursor-not-allowed
        ${ui.variant[variant]}
        ${ui.scale[scale]}
        ${extraCss || ''}`}
      {...rest}
    >
      {children}
    </a>
  )
}
