import React, { useEffect, useMemo } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { ButtonLink } from 'components/Button'
import ButtonConnectWallet from 'components/ButtonConnectWallet'
import Logo from 'components/Logo'
import Container from 'components/Container'
import DialogConnectWallet from 'components/DialogConnectWallet'
import {
  LINK_DOCS,
  LINK_TELEGRAM,
  LINK_TWITTER,
  ROUTE_HISTORY,
  ROUTE_LANDING,
  ROUTE_PREDICTIONS,
} from 'config/constants/routes'
import { useTranslation } from 'contexts/Localization'
import { IconCrown, IconGitbook, IconHome, IconTelegram, IconTwitter } from 'components/Icon'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useGetBnbBalance } from 'hooks/useTokenBalance'
import { formatBigNumber } from 'utils/formatBalance'
import styles from './index.module.css'

const navLinkStyles = 'hidden md:inline-flex text-md focus:outline-none focus:opacity-90 focus:border-b-sm focus:border-accent-green-100 text-accent-green-100 font-semibold'

const PageLayout = (props) => {
  const { children } = props
  const { t } = useTranslation()
  const location = useLocation()
  const { account } = useWeb3React()
  const { balance: bnbBalance } = useGetBnbBalance()
  const bnbBalanceAsBn = useMemo(() => {
    return ethers.BigNumber.from(bnbBalance.toString())
  }, [bnbBalance])
  const balanceDisplay = parseFloat(formatBigNumber(bnbBalanceAsBn)).toFixed(2)

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    (location.pathname === ROUTE_PREDICTIONS || location.pathname === ROUTE_HISTORY)
      ? document.querySelector('html').classList.remove('landing')
      : document.querySelector('html').classList.add('landing')
  }, [location.pathname])
  return (
    <>
      <header className="z-1 border-b-sm border-neutral-layer-500 border-solid py-4 bg-neutral-layer-400 md:bg-neutral-layer-500 flex justify-between">
        <Container extraCss="flex justify-between">
          <div className="flex items-center">
            <Link to={ROUTE_LANDING}>
              <Logo showReduced={account && location.pathname === ROUTE_PREDICTIONS} />
            </Link>
          </div>
          <nav className="md:flex md:items-center md:space-x-8">
            {!location.pathname.match(ROUTE_PREDICTIONS) && (
              <>
                <a rel="noopener noreferrer" href={LINK_TWITTER} target="_blank" className={navLinkStyles}>
                  <span className="sr-only">{t('Twitter')}</span>
                  <IconTwitter className="mx-auto text-xl" />
                </a>
                <a rel="noopener noreferrer" href={LINK_TELEGRAM} target="_blank" className={navLinkStyles}>
                  <span className="sr-only">{t('Telegram')}</span>
                  <IconTelegram className="mx-auto text-xl" />
                </a>
                <ButtonLink
                  extraCss="hidden md:inline-flex items-center"
                  external
                  rel="noopener noreferrer"
                  href={LINK_DOCS}
                  target="_blank"
                  variant="subtle"
                  scale="sm"
                >
                  <IconGitbook className="mie-2" />
                  {t('Documentation')}
                </ButtonLink>
              </>
            )}
            {location.pathname.match(ROUTE_PREDICTIONS) ? (
              <>
                {!account ? (
                  <ButtonConnectWallet variant="primary" scale="sm">
                    {t('Connect wallet')}
                  </ButtonConnectWallet>
                ) : (
                  <div className="flex">
                    <span className="py-1 pis-2 pie-2.5 font-semibold rounded-l-sm text-neutral-fg-100 bg-neutral-layer-500 md:bg-neutral-layer-400">
                      {balanceDisplay} FTM
                    </span>
                    <span className="py-1 flex items-center transform -translate-x-1 px-2 font-semibold rounded-sm text-neutral-layer-500 bg-accent-green-100">
                      <span className="overflow-hidden inline w-15 md:w-20 truncate">{account}</span>
                      <img src="/images/metamask.png" alt="" />
                    </span>
                  </div>
                )}
              </>
            ) : (
              <ButtonLink external={false} to={ROUTE_PREDICTIONS} variant="primary" scale="sm">
                {t('Get started')}
              </ButtonLink>
            )}
          </nav>
        </Container>
      </header>
      <nav className="md:hidden fixed w-full z-10 bottom-0 left-0 py-3 bg-neutral-layer-300 text-sm z-10">
        <Container extraCss="max-w-60 flex justify-between items-center uppercase">
          <NavLink
            className={`${styles.link} flex-grow flex flex-col items-center`}
            exact
            activeClassName={styles.linkActive}
            to={ROUTE_LANDING}
          >
            <IconHome />
            {t('Home')}
          </NavLink>

          <NavLink
            className={`${styles.link} flex-grow flex flex-col items-center`}
            exact
            activeClassName={styles.linkActive}
            to={ROUTE_PREDICTIONS}
          >
            <IconCrown />
            {t('Predictions')}
          </NavLink>
        </Container>
      </nav>
      {children}
      <DialogConnectWallet />
    </>
  )
}

export default PageLayout
