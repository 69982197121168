import React from 'react'
import { IconWarning, IconError, IconCheckMark, IconClose } from 'components/Icon'
import { IAlertProps } from './types'
import ui from './ui'
import styles from './index.module.css'

const icons = {
  danger: <IconError />,
  warning: <IconWarning />,
  success: <IconCheckMark />,
}

const Alert = (props: IAlertProps) => {
  const { children, title, variant, handleRemove } = props

  return (
    <div
      className={`${ui.wrapper.scale.default} ${ui.wrapper.variant[variant]} ${ui.text.variant[variant]} flex relative ${styles.pseudoBackground}`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      {variant !== ('default' || 'info') && <div className="w-5">{icons[variant]}</div>}
      <div
        className={`${ui.text.scale.default} ${ui.text.variant[variant]} flex flex-col backdrop-blur pis-3 relative`}
      >
        <span className={`${ui.title.scale.default} ${ui.title.variant[variant]} relative`}>{title}</span>
        {children}
      </div>
      <button className="text-md absolute inline-end-3" onClick={handleRemove} type="button">
        <IconClose />
        <span className="sr-only">close</span>
      </button>
    </div>
  )
}

export default Alert
