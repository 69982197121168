export default {
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
    250: '',
    4002: '',
  },
  multiCall: {
    56: '',
    97: '',
    250: '0xD58f04112B22Af9Cdb03d713fEFB9e8A49dF6a8C',
    4002: '0x92BACC977F6Fb1c3e8C020C66150A2B61BeFc08C',
  },
  predictions: {
    56: '',
    97: '',
    250: '0xec51497b2e36F7469079A7e3715154301E7C24bE',
    4002: '0x1F581fC234A87c40C5Dfac52AaAFeA83a6fBf8d9', // Testnet
  },
  chainlinkOracle: {
    56: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    97: '',
    250: '0xf4766552D15AE4d256Ad41B6cf2933482B0680dc',
    4002: '0xe04676B9A9A2973BCb0D1478b5E1E9098BBB7f3D', // Testnet
  },
}
