export default {
  wrapper: {
    scale: {
      default: 'px-6 pt-4 pb-6 border-sm border-solid rounded-md max-w-79 2xs:max-w-80 w-full m-auto',
    },
    variant: {
      default: 'border-accent-green-200 border-opacity-10 bg-neutral-layer-500',
    },
  },
  title: {
    scale: {
      default: 'mb-2 font-extrabold text-lg',
    },
    variant: {
      default: 'text-neutral-fg-100',
    },
  },
  text: {
    scale: {
      default: 'font-medium text-base',
    },
    variant: {
      default: 'text-neutral-fg-100',
    },
  },
  iconClose: {
    scale: {
      default: 'rounded-sm p-4 text-xl'
    },
    variant: {
      default: 'text-neutral-fg-100 focus:text-accent-green-100'
    },
  }
}
