import React from 'react'
import { IContainerProps } from './types'

const Container = (props: IContainerProps) => {
  const { children, extraCss } = props

  return (
    <div className={`px-1 2xs:px-4 mx-auto md:px-8 w-full lg:max-w-screen-lg xl:max-w-screen-xl ${extraCss || ''}`}>
      {children}
    </div>
  )
}

export default Container
