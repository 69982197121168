export default {
  variant: {
    disabled: 'disabled:bg-neutral-layer-300 disabled:text-neutral-layer-500',
    primary: 'focus:bg-opacity-85 bg-accent-green-100 hover:bg-opacity-90 text-neutral-layer-500',
    secondary: 'focus:bg-opacity-85 bg-accent-red-100 hover:bg-opacity-90 text-neutral-layer-500',
    subtle: 'bg-accent-green-200 focus:bg-opacity-15 hover:bg-opacity-5 bg-opacity-10 text-accent-green-100',
  },
  scale: {
    default: 'font-semibold text-md py-3 px-6 rounded-sm',
    sm: 'font-semibold text-md px-4 py-1 rounded-sm',
    xs: 'font-semibold text-md px-2 py-1 rounded-md',
    '2xs': 'font-medium text-sm p-0.5 rounded-sm',
    lg: 'font-extrabold text-lg py-2 px-2 rounded-sm',
  },
}
