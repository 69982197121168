import React, { createContext, useState, useContext } from 'react'
import { IDialogConnectWalletProps } from './types'

export const DialogConnectWalletContext = createContext(null)

export const DialogConnectWalletProvider = (props: IDialogConnectWalletProps) => {
  const { children } = props
  const [isVisible, setIsVisible] = useState(false)
  return (
    <DialogConnectWalletContext.Provider
      value={{
        isVisible,
        setIsVisible,
      }}
    >
      {children}
    </DialogConnectWalletContext.Provider>
  )
}

export function useDialogConnectWallet() {
  return useContext(DialogConnectWalletContext)
}
