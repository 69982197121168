export default {
  wrapper: {
    variant: {
      default: 'bg-neutral-fg-100 bg-opacity-10 border-neutral-fg-100',
      info: 'bg-neutral-fg-100 bg-opacity-10 border-neutral-fg-100',
      success: 'bg-accent-green-100 bg-opacity-10 border-accent-green-100',
      warning: 'bg-accent-orange-100 bg-opacity-10 border-accent-orange-100',
      danger: 'bg-accent-red-100 bg-opacity-10 border-accent-red-100',
    },
    scale: {
      default: 'border-sm p-4 rounded-md',
    },
  },
  title: {
    variant: {
      default: 'text-neutral-fg-100',
      info: 'text-neutral-fg-100',
      success: 'text-accent-green-100',
      warning: 'text-accent-orange-100',
      danger: 'text-accent-red-100',
    },
    scale: {
      default: 'text-md font-semibold',
    },
  },
  text: {
    variant: {
      default: 'text-neutral-fg-100',
      info: 'text-neutral-fg-100',
      success: 'text-accent-green-100',
      warning: 'text-accent-orange-100',
      danger: 'text-accent-red-100',
    },
    scale: {
      default: 'text-sm font-medium',
    },
  },
}
