import React, { lazy } from 'react'
import { ROUTE_PREDICTIONS, ROUTE_HISTORY } from 'config/constants/routes'
import { Router, Route, Switch } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import { usePollBlockNumber } from 'state/block/hooks'
import PageLayout from 'components/PageLayout'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import PageLoader from './components/Loader/PageLoader'
import history from './routerHistory'
import 'style/index.css'

const NotFound = lazy(() => import('./views/NotFound'))
const Predictions = lazy(() => import('./views/Predictions'))
const History = lazy(() => import('./views/History'))
const Landing = lazy(() => import('./views/Landing'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  usePollBlockNumber()
  useEagerConnect()

  return (
    <Router history={history}>
      <PageLayout>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Landing />
            </Route>
            <Route path={ROUTE_PREDICTIONS} exact>
              <Predictions />
            </Route>
            <Route path={ROUTE_HISTORY} exact>
              <History />
            </Route>
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </PageLayout>
      <ToastListener />
    </Router>
  )
}

export default React.memo(App)
