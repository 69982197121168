import React from 'react'
import { Dialog as DialogHeadless, Transition } from '@headlessui/react'
import { IconClose } from 'components/Icon'
import { useTranslation } from 'contexts/Localization'
import ui from './ui'
import styles from './index.module.css'
import { IDialogModalProps } from './types'

const DialogModal = (props: IDialogModalProps) => {
  const { isOpen, setIsOpen, title, children } = props
  const { t } = useTranslation()
  return (
    <Transition
      show={isOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <DialogHeadless className="fixed z-10 inset-0 overflow-y-auto" open={isOpen} onClose={() => setIsOpen(false)}>
        <div className="flex items-center justify-center min-h-screen">
          <DialogHeadless.Overlay className={`${styles.backdrop} bg-neutral-layer-500 bg-opacity-75 fixed inset-0`} />
          <div className={`relative ${ui.wrapper.scale.default} ${ui.wrapper.variant.default}`}>
            <button onClick={() =>  setIsOpen(false)} type="button" className={`absolute top-0 inline-end-0 focus:outline-none ${ui.iconClose.variant.default} ${ui.iconClose.scale.default}`}>
              <IconClose />
              <span className="sr-only">{t("Close this modal")}</span>
            </button>
            <DialogHeadless.Title className={`${ui.title.scale.default} ${ui.title.variant.default}`}>
              {title}
            </DialogHeadless.Title>
            <div className={`${ui.text.variant.default} ${ui.text.scale.default}`}>{children}</div>
          </div>
        </div>
      </DialogHeadless>
    </Transition>
  )
}

export default DialogModal
