import React from 'react'
import { ButtonCta } from 'components/Button'
import { useTranslation } from 'contexts/Localization'
import { useDialogConnectWallet } from 'contexts/DialogConnectWallet'
import { IButtonConnectWalletProps } from './types'

const ButtonConnectWallet = (props: IButtonConnectWalletProps) => {
  const { scale, variant, extraCss } = props
  const { t } = useTranslation()
  const { setIsVisible } = useDialogConnectWallet()
  // cconst { onPresentConnectModal } = useWalletModal(login, null, null)

  return (
    <ButtonCta extraCss={extraCss} onClick={() => setIsVisible(true)} variant={variant} scale={scale}>
      {t('Connect wallet')}
    </ButtonCta>
  )
}

export default ButtonConnectWallet
