export interface UserResponse {
  id: string
  createdAt: string
  updatedAt: string
  block: string
  totalBets: string
  totalBetsUp: string
  totalBetsDown: string
  totalFTM: string
  totalFTMUp: string
  totalFTMDown: string
  totalBetsClaimed: string
  totalFTMClaimed: string
  winRate: string
  averageFTM: string
  netFTM: string
  bets?: BetResponse[]
}

export interface BetResponse {
  id: string
  hash: string
  amount: string
  position: string
  claimed: boolean
  claimedAt: string
  claimedBlock: string
  claimedHash: string
  claimedFTM: string
  claimedNetFTM: string
  createdAt: string
  updatedAt: string
  block: string
  user?: UserResponse
  round?: RoundResponse
}

export interface HistoricalBetResponse {
  id: string
  hash: string
  amount: string
  position: string
  claimed: boolean
  user?: UserResponse
  round: {
    id: string
    epoch: string
  }
}

export interface RoundResponse {
  id: string
  epoch: string
  position: string
  failed: boolean
  startAt: string
  startBlock: string
  startHash: string
  lockAt: string
  lockBlock: string
  lockHash: string
  lockPrice: string
  lockRoundId: string
  closeAt: string
  closeBlock: string
  closeHash: string
  closePrice: string
  closeRoundId: string
  totalBets: string
  totalAmount: string
  upBets: string
  upAmount: string
  downBets: string
  downAmount: string
  bets?: BetResponse[]
}

export interface TotalWonMarketResponse {
  totalFTM: string
  totalFTMTreasury: string
}

/**
 * Base fields are the all the top-level fields available in the api. Used in multiple queries
 */
export const getRoundBaseFields = () => `
 id
 epoch
 position
 failed
 startAt
 startBlock
 startHash
 lockAt
 lockBlock
 lockHash
 lockPrice
 lockRoundId
 closeAt
 closeBlock
 closeHash
 closePrice
 closeRoundId
 totalBets
 totalAmount
 upBets
 upAmount
 downBets
 downAmount
`

export const getBetBaseFields = () => `
 id
 hash  
 amount
 position
 claimed
 claimedAt
 claimedHash
 claimedBlock
 claimedFTM
 claimedNetFTM
 createdAt
 updatedAt
`

export const getUserBaseFields = () => `
 id
 createdAt
 updatedAt
 block
 totalBets
 totalBetsUp
 totalBetsDown
 totalFTM
 totalFTMUp
 totalFTMDown
 totalBetsClaimed
 totalFTMClaimed
 winRate
 averageFTM
 netFTM
`
