import React from 'react'
import { IconSpinner } from 'components/Icon'
import { useTranslation } from 'contexts/Localization'

const PageLoader = () => {
  const { t } = useTranslation()

  return (
    <div className="flex-grow flex items-center justify-center">
      <div className="animate-fade-in flex flex-col items-center justify-center text-neutral-fg-100 bg-neutral-fg-100 bg-opacity-25 w-35 h-35 text-center rounded-md">
        <IconSpinner className="w-8 mb-4 fill-neutral-fg-100" />
        {t('Please wait ...')}
      </div>
    </div>
  )
}

export default PageLoader
