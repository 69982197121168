import { ChainId } from '@spookyswap/sdk'
import addresses from 'config/constants/contracts'
import tokens from 'config/constants/tokens'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address): string => {
  const chainId = '250'
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getWftmAddress = () => {
  return getAddress(tokens.wftm.address)
}
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund)
}
export const getPredictionsAddress = () => {
  return getAddress(addresses.predictions)
}
export const getChainlinkOracleAddress = () => {
  return getAddress(addresses.chainlinkOracle)
}
